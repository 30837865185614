import tcImage from 'images/tc.png';
import ccImageHigh from 'images/cc-100.png';
import ccImageLow from 'images/cc-25.png';

const indexView = (() => {
  let $limit,
    $quantityButtons,
    $quantitySelector,
    $variantButtons,
    $submitButton;
  let selectedVariant = null;
  const MAX_ORDER_PRICE = 1000;
  const MIN_ORDER_PRICE = 0.5;
  let priceRules = [];

  const resolveHeaderText = product => {
    if (product.kind == 'code')
      return `${product.name} - Game Code zostanie wysłany na adres e-mail podany w kolejnym kroku`;

    const action = product.name.substr(-1) == 's' ? 'zostaną' : 'zostanie';
    return `${product.name} ${action} wysłane na postać:`;
  };

  const setHeaderText = product => {
    $('.orderFor h3').text(resolveHeaderText(product));
  };

  const setPlan = $plan => {
    if ($plan.length == 0) return;

    $('.plan').removeClass('activePlan');
    $plan.addClass('activePlan');

    const data = $plan.data();
    if (data.slideable) initSlider(data.variant);
    else renderProductVariants(data.product);

    setView(data.slideable);
    setHeaderText(data.product);
  };

  const formattedPrice = (variant, quantity = 1, adjustment = 0) => {
    return `${Math.max(
      quantity * variant.price + adjustment,
      MIN_ORDER_PRICE
    ).toFixed(2)} zł`;
  };

  const formattedAmount = (variant, quantity) => {
    const amount = (variant.weight || 1) * quantity;

    if (variant.unit) {
      if (variant.unit == 'k') {
        if (amount < 1000) return `${amount} k`;

        if (amount >= 1000) return `${amount / 1000} kk`;
      }

      return `${amount} ${variant.unit.toUpperCase()}`;
    }

    return amount;
  };

  const setSubmitButtonState = show => {
    show
      ? $submitButton.removeClass('notActive')
      : $submitButton.addClass('notActive');
  };

  const resolveStackSize = variant => {
    if (variant.unit == 'k') return { min: 10, max: 100 };

    return { min: 1 };
  };

  const unitStackImage = (variant, amount) => {
    if (variant.unit == 'k') return amount >= 50 ? ccImageHigh : ccImageLow;

    return tcImage;
  };

  const renderUnits = (variant, quantity) => {
    quantity = resolveQuantity(variant, quantity);

    const $range = $('.rangeResult').empty();
    const stackSize = resolveStackSize(variant);
    let totalAmount = parseFloat(
      (((variant.weight || 1) * quantity) / stackSize.min).toFixed(1)
    );

    while (totalAmount > 0) {
      const size = stackSize.max
        ? Math.min(totalAmount, stackSize.max)
        : totalAmount;

      $range.append(`
        <div>
          <img src=${unitStackImage(variant, totalAmount)}>
          <span>${size}</span>
        </div>`);

      totalAmount = parseFloat((totalAmount - size).toFixed(1));
    }
  };

  const updateSummary = (variant, quantity) => {
    quantity = resolveQuantity(variant, quantity);

    const adjustment = getPriceAdjustment(variant, quantity);
    const amount = formattedAmount(variant, quantity);
    const price = formattedPrice(variant, quantity, adjustment);

    $('.rangeCount b').html(amount);
    $('.rangePrice b').html(price);
    $('.summary b').html(price);
    $quantitySelector.val(quantity);

    setSubmitButtonState(quantity > 0);
    updateFormVariant(variant.id, quantity);
  };

  const setView = slider => {
    if (slider) {
      $('.username').show();
      $('.moreDetails').show();
      $('.selectRange').show();
      $quantityButtons.hide();
      $variantButtons.hide();
      $variantButtons.html('');
    } else {
      $('.username').hide();
      $('.moreDetails').hide();
      $('input:checked').removeAttr('checked');
      $('.selectRange').hide();
      $variantButtons.show();
    }

    $limit.hide();
  };

  const buildVariantOption = variant => {
    return `
      <label>
        <input class='variant-option' type='radio' name='package' data-variant='${JSON.stringify(
          variant
        )}'>
        <div>
          ${variant.name}
          <span>${formattedPrice(variant)}</span>
        </div>
      </label>
    `;
  };

  const renderProductVariants = product => {
    $variantButtons.empty();

    product.variants.forEach(variant => {
      $variantButtons.append(buildVariantOption(variant));
    });

    $variantButtons.promise().then(() => {
      $('.variant-option')
        .first()
        .trigger('click');
    });
  };

  const updateLimitInfo = variant => {
    $limit.find('div b').html(variant.stock_quantity);
    $limit.show();
  };

  const orderMaxQuantity = variant => {
    const maxQuantity = Math.floor(MAX_ORDER_PRICE / variant.price);

    if (variant.unlimited) return maxQuantity;

    return Math.min(maxQuantity, variant.stock_quantity);
  };

  const orderMinQuantity = variant => {
    const defaultQuantity = variant.default_selected_min_quantity || 1;

    if (!variant.unlimited)
      return Math.min(variant.stock_quantity, defaultQuantity);

    return defaultQuantity;
  };

  const resolveQuantity = (variant, quantity = null) => {
    if (quantity == null) {
      const defaultQuantity = Math.max(
        orderMinQuantity(variant),
        variant.default_selected_quantity
      );
      quantity = Math.min(orderMaxQuantity(variant), defaultQuantity) || 1;
    }

    if (!variant.unlimited && quantity > variant.stock_quantity)
      quantity = variant.stock_quantity;

    return Math.max(quantity, 0);
  };

  const initSlider = variant => {
    updateSummary(variant);
    renderUnits(variant);

    $('#slider').slider({
      value: resolveQuantity(variant),
      min: Math.min(orderMinQuantity(variant), orderMaxQuantity(variant)),
      max: orderMaxQuantity(variant),
      step: 1,
      slide: (_, slider) => {
        updateSummary(variant, slider.value);
        renderUnits(variant, slider.value);
      }
    });
  };

  const updateFormVariant = (id, quantity) => {
    $('.variant-id').val(id);
    $('.variant-quantity').val(quantity);
  };

  const setFirstPlan = () => {
    setPlan($('.plan').first());
  };

  const adjustedVariantQuantity = type => {
    const currentQuantity = Number($quantitySelector.val()) || 0;
    return type == '+' ? currentQuantity + 1 : currentQuantity - 1;
  };

  const initEventListeners = () => {
    $('.plan').on('click', event => {
      setPlan($(event.currentTarget));
    });

    $(document).on('click', '.variant-option', event => {
      const variant = $(event.currentTarget).data('variant');
      selectedVariant = variant;

      updateLimitInfo(variant);
      updateSummary(variant);
      $quantityButtons.show();
    });

    $('.quantityNav').on('click', event => {
      if (!selectedVariant) return;

      const quantity = adjustedVariantQuantity(
        $(event.currentTarget).data('type')
      );
      updateSummary(selectedVariant, quantity);
    });
  };

  const initValidation = () => {
    $('#orderStep-1 form').validate({
      submitHandler: form => {
        if (!$submitButton.hasClass('notActive')) form.submit();
      }
    });
  };

  const isRuleApplicable = (rule, variant, quantity) => {
    if (rule.products.length === 0 && !rule.preferred_quantity_min) {
      return true;
    }

    let productMatch = true;
    if (
      rule.products.length !== 0 &&
      !rule.products.some(product => product.id === variant.product_id)
    ) {
      productMatch = false;
    }

    let quantityMatch = true;
    if (
      rule.preferred_quantity_min &&
      quantity &&
      quantity < rule.preferred_quantity_min
    ) {
      quantityMatch = false;
    }

    return productMatch && quantityMatch;
  };

  const getRuleValue = (rule, variant, quantity) => {
    if (rule.value_type === 'percentage') {
      return (variant.price * quantity * rule.value) / 100.0;
    }

    if (rule.allocation_method === 'across' && rule.value_type === 'fixed') {
      return rule.value;
    }

    return rule.value * quantity;
  };

  const getPriceAdjustment = (variant, quantity) => {
    let total = 0;

    priceRules.forEach(rule => {
      if (isRuleApplicable(rule, variant, quantity)) {
        total += getRuleValue(rule, variant, quantity);

        if (rule.discard_subsequent) {
          return;
        }
      }
    });

    return total;
  };

  const initLocalVariables = () => {
    $limit = $('.gamecodeLimit');
    $quantityButtons = $('.selectRangeButtonsQuantity');
    $variantButtons = $('.selectRangeButtons');
    $submitButton = $('.stepSummary button');
    $quantitySelector = $('.quantity-selector');
    priceRules = $('#orderStep-1').data('price-rules');
  };

  const init = () => {
    initLocalVariables();
    setFirstPlan();
    initValidation();
    initEventListeners();
  };

  return { init: init };
})();

const productViews = {
  index: () => {
    indexView.init();
  }
};

export default productViews;
