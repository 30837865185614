import Rails from 'rails-ujs';

import 'jquery';
import 'jquery-validation'

import 'jqueryui'
import 'jquery-ui-touch-punch'
import 'jqueryui/jquery-ui.css'

import '../src/shop/general/validation-lang'
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap'

import 'datatables.net-bs4';
import 'datatables.net-bs4/css/dataTables.bootstrap4.css';

import 'bootstrap-select/dist/js/bootstrap-select.min';
import 'bootstrap-select/dist/css/bootstrap-select.min.css'

import '@fortawesome/fontawesome-free/css/all'

import 'owl.carousel/dist/assets/owl.carousel';
import 'owl.carousel/dist/owl.carousel'

import '../src/shop/general/jquery.playSound'
import '../src/shop/styles';

import generalViews from 'src/shop/general';
import productViews from 'src/shop/products';
import orderViews from 'src/shop/orders';
import accountViews from 'src/shop/account';
import charactersViews from 'src/shop/characters';

require.context('../images', true)

window.mmo_trade = {
  views: {
    shop: {
      general: generalViews,
      products: productViews,
      orders: orderViews,
      account: accountViews,
      characters: charactersViews
    }
  }
}

$(() => {
  Rails.start();
})
