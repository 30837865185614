import dataTableTranslationsPL from '../dataTablesPolish.json';

const accountView = (() => {
  const fetchInputFileUrl = (input) => {
    if (input.files && input.files[0]) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const $avatar = $('.accountAvatar');
        $avatar.attr('src', e.currentTarget.result);
      }

      reader.readAsDataURL(input.files[0]);
    }
  }

  const initOrderHistory = () => {
    $('#myHistory').dataTable({
      filter: false,
      language: dataTableTranslationsPL,
      order: [],
      columnDefs: [{
        targets  : 'no-sort',
        orderable: false,
      }]
    });
  }

  const initPointsHistory = () => {
    $('#myTradeHistory').dataTable({
      filter: false,
      language: dataTableTranslationsPL,
      order: [],
      columnDefs: [{
        targets  : 'no-sort',
        orderable: false,
      }]
    });
  }

  const initItemPicker = () => {
    $(".itemPicker").selectpicker();
  }

  const changeTab = (tabname) => {
    const tabElem = $(".accountNav ul li").find(`a[data-tab="${tabname}"]`);

    $('.accountTab').removeClass('activeTab');
    $('.acTab').hide();

    tabElem.addClass('activeTab');
    $(`.acTab-${tabname}`).show();
  }

  const highlightLastActiveTab = () => {
    const defaultTab = "history";
    const lastTab = localStorage.getItem('activeTab');
    if(lastTab == null) {
      changeTab(defaultTab);
    } else {
      changeTab(lastTab);
    }
  }

  const initEventListeners = () => {
    $(document).on('change', '.btn-file :file', (event) => {
      const $input = $(event.currentTarget);
      const label  = $input.val().replace(/\\/g, '/').replace(/.*\//, '');
      $input.trigger('fileselect', [label]);
    });

    $('.btn-file :file').on('fileselect', (event, label) => {
      const $input = $(event.currentTarget).parents('.input-group').find(':text');

      if($input.length) {
        $input.val(label);
      } else {
        if(label) alert(label);
      }
    });

    $('#userImage').change((event) => {
      fetchInputFileUrl(event.currentTarget);
    });

    $('.changeAvatar').click((event) => {
      event.preventDefault();
      $('#userImage').trigger('click');
    });

    $('.accountTab').click((event) => {
      const $clickedTab = $(event.currentTarget);
      const clickedTabName = $clickedTab.data('tab');
      $('.accountTab').removeClass('activeTab');
      $('.acTab').hide();
      $clickedTab.addClass('activeTab');
      $(`.acTab-${clickedTabName}`).show();
      localStorage.setItem('activeTab', clickedTabName);
    });

    $('.affilateLink').focus((event) => {
      $(event.currentTarget).select();
    });
  }

  const initValidation = () => {

  }

  const init = () => {
    initItemPicker();
    initOrderHistory();
    initPointsHistory();
    highlightLastActiveTab();
    initEventListeners();
  }

  return { init: init }
})();

const accountsViews = {
  dashboard: () => {
    accountView.init();
  }
}

export default accountsViews;