const summaryView = (() => {
  let $form;
  let orderNumber = null;

  const recalculatePrice = (orderNumber, data, callback, errCallback) => {
    $.ajax({
      type: 'GET',
      url: `/orders/${orderNumber}/recalculate_price`,
      data: data
    })
    .then(callback)
    .catch(errCallback)
  }

  const refreshSummaryPrice = (paymentMethodId) => {
    recalculatePrice(orderNumber, {
      payment_method_id: paymentMethodId
    }, (response) => {
      if (response.total_price !== null) {
        $('.price').html(response.total_price);
      }
      if (response.price_in_points !== null) {
        $('.points').html(`+${response.price_in_points} PKT`);
      }
    });
  }

  const canShowTransferDetails = (paymentMethodType) => {
    return paymentMethodType == 'traditional_transfer' ||
      paymentMethodType == 'phone_transfer' ||
      paymentMethodType == 'international_transfer'
  }

  const updateTransferDetailsVisibility = (paymentMethodType) => {
    if (canShowTransferDetails(paymentMethodType)) {
      $('.transferDetails').show();
      $('#traditionalTransferDestination').toggle(paymentMethodType == 'traditional_transfer');
      $('#phoneTransferDestination').toggle(paymentMethodType == 'phone_transfer');
      $('#internationalTransferDestination').toggle(paymentMethodType == 'international_transfer');
    } else {
      $('.transferDetails').hide();
    }
  }

  const initEventListeners = () => {
    $('.paymentMethod input').on('click', event => {
      const $methodInput  = $(event.currentTarget);
      const $description  = $('.paymentDescription');

      $('.paymentMethod label .selected').hide();
      $methodInput.parent().find('.selected').show();
      $description.html($methodInput.data('description'));
      $description.show();

      updateTransferDetailsVisibility($methodInput.data('type'));
      refreshSummaryPrice($methodInput.val());
    });

    const $submitButton = $form.find('.stepSubmit button');

    $form.on('keyup change paste', 'input, select, textarea', () => {
      if ($form.valid())
        $submitButton.removeClass('disabled');
      else
        $submitButton.addClass('disabled');
    });
  }

  const initValidation = () => {
    $form.validate({
      lang: 'pl',
      rules: {
        'order[billing_info_attributes][email]': {
          required: true,
          email: true
        },
        'order[billing_info_attributes][payment_method]': {
          required: true
        },
      },
      submitHandler: form => {
        if (form.valid())
          form.submit();
      }
    });
  }

  const init = () => {
    $form = $('#orderStep-2 form');
    orderNumber = $('.orderDetails').data('number');
    initValidation();
    initEventListeners();
  }

  return { init: init }
})();

const orderViews = {
  summary: () => {
    summaryView.init();
  }
}

export default orderViews;
