import loading from 'images/loading.gif';
import 'paginationjs';

const indexView = (() => {
  const SLIDER_VALUE_MIN = 8, SLIDER_VALUE_MAX = 1500;

  let sliderValues   = [SLIDER_VALUE_MIN, SLIDER_VALUE_MAX];
  let charactersList = [];

  const filterCharacters = (serverType, profession) => {
    const isAnyProfession = profession == 'all';
    const isAnyServerType = serverType == 'all';

    const filteredCharacters = charactersList.filter(character => {
      return (isAnyServerType && isAnyProfession) ||
        (!isAnyServerType && isAnyProfession) && character.server_type == serverType ||
        (isAnyServerType && !isAnyProfession) && character.profession == profession ||
        (!isAnyServerType && !isAnyProfession) && character.server_type == serverType && character.profession == profession
    });

    const [levelMin, levelMax] = sliderValues;

    return filteredCharacters.filter(
      character => character.level >= levelMin && character.level <= levelMax
    );
  };

  const buildCharacterTemplate = character => {
    const tag = character.tag_name ?
      `<div class="characters__item__badge" style="background-color: ${character.tag_color}">${character.tag_name}</div>` : '';

    return `<div class="characters__item" data-proffession="${character.profession}" data-world="${character.server_type}">
        <div class="characters__item__image" style="background-image: url('${character.thumbnail_image}')">
          <a href="${character.url}"></a>
          ${tag}
        </div>
        <div class="characters__item__informations">
          <h3 class="characters__item__name"><a href="${character.url}">${character.name}</a></h3>
          <p class="characters__item__world">${character.server_name}</p>
          <p class="characters__item__price">${character.price}</p>
        </div>
        <div class="characters__item__date">Fake dane od: ${character.data_change_acceptance_date}</div>
        <div class="characters__item__action">
          <a href="${character.url}" class="characters__item__button">Więcej szczegółów</a>
        </div>
    </div>`;
  }

  const initCharactersList = charactersData => {
    const dataContainer = $('#characters');
    const paginationContainer = $('.character-list__pagination');
    const notFound = $('.character-list__not-found');

    dataContainer.show();
    notFound.hide();

    paginationContainer.pagination({
      dataSource: charactersData,
      pageSize: 12,
      pageRange: 1,
      autoHidePrevious: true,
      autoHideNext: true,
      classPrefix: 'character-list__pagination__item pagination',
      activeClassName: 'character-list__pagination__item--active',
      callback: (data, pagination) => {
        if (data.length != 0) {
          dataContainer.empty();

          for (const character of data) {
            dataContainer.append(buildCharacterTemplate(character));
          }
        } else {
          dataContainer.hide();
          notFound.show();
        }            
      }
    })
  }

  const initLevelSlider = () => {
    $("#level-range").slider({
      range: true,
      min: SLIDER_VALUE_MIN,
      max: SLIDER_VALUE_MAX,
      values: [SLIDER_VALUE_MIN, SLIDER_VALUE_MAX],
      slide: (event, ui) => {
        const [from, to] = ui.values;
        $('.character-filter__level').html(`${from}-${to}`);
        sliderValues = [from, to];
        updateView();
      }
    });
  }

  const updateView = () => {
    const serverType   = $('input[name=world]:checked').val();
    const profession   = $('input[name=proffession]:checked').val();
    const filteredData = filterCharacters(serverType, profession);
    initCharactersList(filteredData);
  }

  const fetchCharacters = () => {
    return $.ajax({
      url: '/characters',
      contentType: 'application/json',
      dataType: 'json'
    });
  }

  const init = () => {
    $.ajaxSetup({ cache: false })

    fetchCharacters().then((data) => {
      charactersList = data;

      initCharactersList(charactersList); 
      initLevelSlider();

      $('input[name=world], input[name=proffession]').on('click', () => updateView());
    });
  }

  return { init: init }
})();

const characterView = (() => {  
  const toggleVisibleImage = () => {
    $('input[name=other-pic]').on('click', e => {
      const imageBlock = $('.single-character__image__pic');
      const imageLink  = $('.single-character__image__pic--show');
      const newImage   = $('input[name=other-pic]:checked').val();

      imageBlock.css({
        'background-image': `url(${newImage}), url(${loading})`
      });

      imageLink.attr('href', newImage);
    });
  }

  const init = () => {
    toggleVisibleImage();
  }

  return { init: init }
})();

const charactersViews = {
  index: () => {
    indexView.init();
  },
  show: () => {
    characterView.init();
  }
}

export default charactersViews;
