import proofSound from 'audio/proof.mp3';
import tcImage from 'images/tc.png';
import tgImage from 'images/cc-100.png';

const indexView = (() => {
  const MIN_DESKTOP_WIDTH = 768;

  let $header,
      $footer,
      $main,
      $window;

  /* OwlCarousel */
  const initOwlCarousel = () => {
    $('.owl-carousel').owlCarousel({
      loop: true,
      autoplay: true,
      dots: false,
      margin: 5,
      nav: false,
      touchDrag: false,
      mouseDrag: false,
      responsive:{
        0: {
          items:2
        },
        550: {
          items:5
        },
        600: {
          items:7
        },
        1000: {
          items:10
        }
      }
    });
  }

  /* Smooth Scroll */
  const slideTo = ($clicked) => {
    const minusPx = 60;
    const animationTime = 350;

    $('html, body').animate({
      scrollTop: $clicked.offset().top - minusPx
    }, animationTime, 'swing');
    return false;
  }

  /* Modal */
  const setModalState = (action, modalId) => {
    const modal = $("#modal");
    if (action == "hide") {
      modal.hide();
    }
    if (action == "show") {
      const newModalTab = `#modal #${modalId}`;
      $("#modal .my-modal").addClass("my-modal-hidden");
      $(newModalTab).removeClass("my-modal-hidden");
      modal.show();
    }
  }

  /* Event Listeners */
  const initEventListeners = () => {
    /* Smooth Scroll */
    $('.slideTo').on('click', (e) => {
      e.preventDefault();
      $('#mobileMenu').hide();
      return slideTo($($(e.currentTarget).attr('href')));
    });

    /* Show Games in menu */
    $('.showGames').on('click', (e) => {
      e.preventDefault();
      $(e.currentTarget).siblings('ul').toggle();
    });

    /* Show Lang */
    $('.langSelected').on('click', (e) => {
      e.preventDefault();
      $(e.currentTarget).siblings('.toggle').toggle();
    });

    /* Mobile Menu */
    $('.mobileMenuButton').on('click', (e) => {
      e.preventDefault();
      $('#mobileMenu').slideToggle();
    });

    /* Modal init */
    $('#userNavigation li a').on('click', (event) => {
      const modalParam = event.currentTarget.getAttribute('data-modal');
      if(modalParam) {
        event.preventDefault();
        setModalState('show',modalParam);
      }
    });

    /* Points modal init */
    $('.pointExchange').click(() => {
      setModalState('show','points-modal');
    });

    /* Modal hide */
    $('#modal .modal-exit').on('click', (e) => {
      e.preventDefault();
      setModalState('hide');
    });

    $window.resize(() => {
      if ($window.width() >= MIN_DESKTOP_WIDTH) {
        $('#mobileMenu').hide();
      }
    });

    /* Validate order-0 form */
    $('#top form').validate({
      lang: 'pl',
      rules: {
        nickname: {
          required: true
        }
      },
      messages: {},
      errorElement : 'div',
      errorLabelContainer: '.name-alert-container .js-alert'
    });

    /* Validate footer ContactForm */
    $('#contactForm').validate({
      lang: 'pl',
      rules: {
        username: 'required',
        email: {
          required: true,
          email: true
        },
        message: 'required'
      },
      messages: {},
      submitHandler: (form) => {
        form.submit();
      }
    });
  }

  const initGG = () => {
    $('<script async id="gg-widget-script", src="https://widget.gg.pl/resources/js/widget.js"/>')
      .insertBefore($('script')
        .first()
      );
  }

  const initLocalVariables = () => {
    $header = $('header');
    $footer = $('footer');
    $main   = $('main');
    $window = $(window);
  }

  /* Main Init */
  const init = () => {
    initLocalVariables();
    initOwlCarousel();
    initEventListeners();
    initGG();
  }

  return { init: init }
})();

const generalViews = {
  index: () => {
    indexView.init();
  }
}

export default generalViews;
